export default function () {
  return [
    { path: "/error", component: () => import("./pages/Error.vue") },

    {
      path: "/",
      component: () => import("./layouts/Layout.vue"),
      children: [
        {
          name: "home",
          path: "",
          component: () =>
            import(/* webpackChunkName: 'HomePage' */ "./pages/HomePage.vue"),
        },
        {
          name: "business",
          path: "/business/:id",
          component: () =>
            import(/* webpackChunkName: 'HomePage' */ "./pages/Business.vue"),
        },
        {
          path: "/businessVerified",
          component: () =>
            import(
              /* webpackChunkName: 'HomePage' */ "./pages/BusinessVerified.vue"
            ),
        },
        {
          path: "/partners",
          name: "partners",
          component: () =>
            import(/* webpackChunkName: 'HomePage' */ "./pages/Partners.vue"),
        },
        {
          path: "/businesses",
          name: "businesses",
          component: () =>
            import(/* webpackChunkName: 'HomePage' */ "./pages/Businesses.vue"),
        },
        {
          path: "/businesses/add",
          name: "addBusinesses",
          component: () =>
            import(
              /* webpackChunkName: 'HomePage' */ "./pages/AddBusiness.vue"
            ),
        },
        {
          path: "/partner/:id?",
          component: () =>
            import(/* webpackChunkName: 'HomePage' */ "./pages/Partner.vue"),
        },
        { path: "logout" },
      ],
    },
  ];
}
