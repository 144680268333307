import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    errorMessage: null,
    successMessage: null,
    isLoading: false,
  },
  mutations: {
    errorMessage(state, message) {
      state.errorMessage = message;
    },
    isLoading(state, b) {
      state.isLoading = b;
    },
    successMessage(state, message) {
      state.successMessage = message;
    },
    clearMessages(state) {
      state.errorMessage = null;
      state.successMessage = null;
    },
  },
});

export default store;
