import Vue from "vue";
import App from "./App.vue";

import Store from "./store.js";

import Provision from "./provision";

import vueDebounce from "vue-debounce";
Vue.use(vueDebounce);

import JsonViewer from "vue-json-viewer";
Vue.use(JsonViewer);

import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

Vue.use(require("vue-moment"));

import "./my.scss";
import "./my_dark.scss";

Vue.config.productionTip = false;

Provision(Vue, Store).then((router) => {
  new Vue({
    router,
    render: (h) => h(App),
    store: Store,
  }).$mount("#app");
});
