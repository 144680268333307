<template>
  <div>
    <b-overlay :show="isLoading" rounded="lg" opacity="0" no-wrap fixed />
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return { isLoading: false };
  },
  watch: {
    "$store.state.successMessage": function (message) {
      if (!message) return;

      this.$store.commit("clearMessages");

      this.$bvToast.toast(message, {
        title: `Success`,
        variant: "success",
        solid: true,
      });
    },
    "$store.state.isLoading": function (b) {
      this.isLoading = b;
    },
    "$store.state.errorMessage": function (message) {
      // Our fancy notification (2).
      if (!message) return;
      this.$store.commit("clearMessages");

      this.$bvToast.toast(message, {
        title: `Error`,
        variant: "danger",
        solid: true,
      });
    },
  },
};
</script>

<style></style>
