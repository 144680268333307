import createAuth0Client from "@auth0/auth0-spa-js";
import VueRouter from "vue-router";
import axios from "axios";
import Routes from "./routes.js";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

export default async function Provision(Vue, store) {
  Vue.use(require("vue-moment"));
  setupFilters(Vue);
  setupAxios(Vue, store);
  setupVeeValidate(Vue);
  await setupAuth0(Vue);
  return await setupRoutes(Vue);
}

function setupFilters(Vue) {
  Vue.filter("currency", function (value) {
    if (typeof value !== "number") {
      return value;
    }
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return formatter.format(value);
  });
}
async function setupAuth0(Vue) {
  const auth0 = await createAuth0Client({
    domain: process.env.VUE_APP_AUTH_DOMAIN,
    client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URI,
    audience: process.env.VUE_APP_AUTH_AUDIENCE,
    cacheLocation: "localstorage",
  });

  Vue.prototype.$auth0 = auth0;
}

function setupVeeValidate(Vue) {
  Vue.component("validation-observer", ValidationObserver);
  Vue.component("validation-provider", ValidationProvider);

  Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]);
  });
}

function setupRoutes(Vue) {
  const routes = Routes();

  const router = new VueRouter({
    mode: "history",
    routes,
  });

  router.beforeEach(async (to, from, next) => {
    console.log("Authenticating...");

    var isAuthenticated = await Vue.prototype.$auth0.isAuthenticated();

    console.log("isAuthenticated: " + isAuthenticated);

    console.log(to.path);
    if (
      !isAuthenticated &&
      !(
        to.path == "/auth_callback" ||
        to.path == "/logout" ||
        to.path == "/login" ||
        to.path == "/error"
      )
    ) {
      console.log("Redirecting to login...");

      await Vue.prototype.$auth0.loginWithRedirect();
      return;
    }

    if (to.path == "/login") {
      await Vue.prototype.$auth0.loginWithRedirect();
    }

    if (to.path == "/logout") {
      Vue.prototype.$auth0.logout();
      return;
    }

    if (to.path == "/auth_callback") {
      console.log("Handling auth callback...");

      try {
        await Vue.prototype.$auth0.handleRedirectCallback();
      } catch (error) {
        Vue.prototype.$auth0.logout();
        next("/error?e=" + error);
        return;
      }

      let user = await Vue.prototype.$auth0.getUser();

      if (user) {
        window.localStorage.setItem("user", JSON.stringify(user));
      } else {
        Vue.prototype.$auth0.logout();
        return;
      }

      user = await Vue.prototype.$auth0.getUser();
      window.localStorage.setItem("user", JSON.stringify(user));

      next({ path: "/" });
    }

    next();
  });

  Vue.use(VueRouter);

  return router;
}

function setupAxios(Vue, store) {
  var axiosClient = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER,
    json: true,
  });

  Vue.prototype.$client = axiosClient;

  axiosClient.interceptors.request.use(
    async function (config) {
      if (!((config.data && config.data.noProgress) || config.noProgress))
        store.commit("isLoading", true);

      var token;
      try {
        token = await Vue.prototype.$auth0.getTokenSilently();
      } catch (error) {
        await Vue.prototype.$auth0.loginWithRedirect();
      }

      config.headers["Authorization"] = "Bearer " + token;

      return config;
    },
    function (error) {
      // Do something with request error
      store.commit("isLoading", false);
      store.commit("errorMessage", "There was an error making the request");

      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosClient.interceptors.response.use(
    function (response) {
      store.commit("isLoading", false);

      //handle expected error
      if (!response.data.isSuccess)
        store.commit("errorMessage", response.data.errors);

      return response;
    },
    function (error) {
      store.commit("errorMessage", "Unknown error occured");

      // unexpected error
      console.log(error);

      store.commit("isLoading", false);
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
  console.log("Axios setup...");
}
